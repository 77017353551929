import React, {useCallback, useRef} from "react";
import Layout from "../layouts/de";
import { scrollElementIntoView } from "../components/helper";
import { Col, Container, Row } from "reactstrap";
import adobe from "../assets/icons/icons8-adobe-xd-color.svg";
import invision from "../assets/icons/invision.svg";
import zeplin from "../assets/icons/zeplin.svg";
import advantage1 from "../assets/icons/emojis/technologin.png";
import advantage2 from "../assets/icons/emojis/rakete.png";
import advantage3 from "../assets/icons/emojis/zahnrad.png";
import slider1 from "../images/screenshot-kickass-by-laura-philipp.png";
import slider2 from "../images/screenshot-my-source-nutrition.png";
import slider3 from "../images/screenshot-baubranche-jobs-de.png";
import step1 from "../images/photo-1565728744382-61accd4aa148-compressor.jpeg";
import step2 from "../images/photo-1552664688-cf412ec27db2-compressor.jpeg";
import step3 from "../images/photo-1545235617-9465d2a55698-compressor.jpeg";
import step4 from "../images/photo-1531538606174-0f90ff5dce83-compressor.jpeg";
import header from "../images/luke-chesser-vCF5sB7QecM-unsplash-compressor.jpg";
import SectionContact from "../components/sections/sectioncontact";
import Collapsible from "react-collapsible";

import {throttle} from "lodash";

function Uxuidesign(props) {
  const scrollTop = useRef(0);
  if (typeof document !== `undefined`) {
    document.documentElement.classList.remove('nav-open');
  }
  const resizeListener = useCallback(() => {
    document.querySelectorAll('.slider-img').forEach(el => {
      let height = el.offsetWidth * 0.62;
      el.style.minHeight = height + 'px';
    });
  }, []);
  const scrollListener = useCallback(() => {
    let st = window.pageYOffset || document.documentElement.scrollTop;
    if (document.getElementById('page-slider')) {
      let scroll = document.getElementById('page-slider').getBoundingClientRect();

      if (scroll.top <= window.innerHeight && scroll.top >= -scroll.height) {
        let windowHeight = window.innerHeight;
        let windowWidth = window.innerWidth;
        let elementWidth = scroll.width;
        let elementHeight = scroll.height;
        if (st > scrollTop.current){
          scroll = scroll.bottom;
        } else {
          scroll = scroll.bottom;
        }
        let elementWidthTotal = 0;
        if (windowWidth >= 992) {
          elementWidthTotal = (elementWidth - 716) * 3 + 2 * 24;
        } else if (windowWidth >= 576) {
          elementWidthTotal = (elementWidth - 258) * 3 + 2 * 24;
        } else {
          elementWidthTotal = (elementWidth - 80) * 3 + 2 * 16;
        }
        let difference = elementWidthTotal - elementWidth;
        let heightTotal =  windowHeight + elementHeight;
        let percentage = scroll / heightTotal;
        if (percentage >= 0 && percentage <= 1) {
          let reversePercentage = 1 - percentage;
          if (st > scrollTop.current){
            document.getElementById('page-slider').style.transform = 'translateX(-' + (reversePercentage * difference) + 'px)';
          } else {
            document.getElementById('page-slider').style.transform = 'translateX(-' + (difference - (percentage * difference)) + 'px)';
          }
        }
      }
    }
    scrollTop.current = st <= 0 ? 0 : st;
  }, []);
  const loadListener = useCallback(() => {
    document.querySelectorAll('.slider-img').forEach(el => {
      let height = el.offsetWidth * 0.62;
      el.style.minHeight = height + 'px';
    });
  }, []);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    if (typeof window !== `undefined` && window.location.hash !== '') {
      if (document.getElementById(window.location.hash.replace('#', '')) !== null) {
        setTimeout(() => {
          scrollElementIntoView(document.getElementById(window.location.hash.replace('#', '')));
        }, 500);
      }
    }
    document.querySelectorAll('.slider-img').forEach(el => {
      let height = el.offsetWidth * 0.62;
      el.style.minHeight = height + 'px';
    });
    window.addEventListener('resize', throttle(resizeListener, 200));
    window.addEventListener('load', loadListener);
    window.addEventListener('scroll', throttle(scrollListener, 20));
    return () => {
      window.removeEventListener('resize', resizeListener);
      window.removeEventListener('scroll', scrollListener);
      window.removeEventListener('load', loadListener);
    }
  }, []);

  return (
    <Layout classNameChild="uxuidesign" location={props.location} title="UX-Design Agentur aus Karlsruhe | newww.agency" description="Als UX-Design aus Karlsruhe entwickeln wir einmalige User Experiences. Experten in Brand Design, App Design und UX/UI Design." keywords="Online-Shop, Konzeption, UX/UI-Design, Entwicklung, Shopware, Magento, Shopify, WooCommerce, " robots="index, follow, notranslate">
      <div className="section section-dark section-header" id="uxuidesign">
        <Container>
          <Row>
            <Col lg="6" md="12" sm="12" xs="12">
              <div className="text-wrapper">
                <h3 className="header-subheadline">Einmalige User Experiences</h3>
                <h1 className="header-headline">Als UX-Design-Agentur entwickeln wir einmalige User Experiences.</h1>
                <a className="create-contact dark" href="mailto:hey@newww.agency?subject=Kontaktanfrage">Kontakt aufnehmen</a>
              </div>
            </Col>
            <Col lg="6" md="12" sm="12" xs="12">
              <div className="img-wrapper" style={{backgroundImage: `url(${header})`}} />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="section section-tripple" id="advantages">
        <Container>
          <h2 className="headline">Deine Experten für digitale Produkte und Nutzererlebnisse.</h2>
          <p className="description">Unsere Leistungen konzentrieren sich auf die Entwicklung von Produktstrategien, die alle Anforderungen an Usability und Design erfüllen. Wir erstellen innovative Designs für mobile Apps, Online-Shops und Websites.</p>
          <Row>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${advantage1})`}}/>
                <h3 className="box-title">Passgenaue Designs</h3>
                <p className="box-description">Wir entwickeln passgenaue Nutzer- und Markenerlebnisse, die bei den Nutzern Begeisterung auslösen und sich durch ihre Einfachheit auszeichnen.</p>
              </div>
            </Col>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${advantage2})`}}/>
                <h3 className="box-title">Höhere Conversions</h3>
                <p className="box-description">Wir helfen dir dabei, deine Ideen und Vorstellungen in Markterfolg zu übertragen und entwickeln Lösungen, die Conversions steigern.</p>
              </div>
            </Col>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${advantage3})`}}/>
                <h3 className="box-title">Optimierte Ressourcen</h3>
                <p className="box-description">Bei uns steht nicht das Design an sich, sondern das Endergebnis im Vordergrund. Denn nur so, werden vorhandene Ressourcen optimal genutzt.</p>
              </div>
            </Col>
          </Row>
          <a className="create-contact" href="mailto:hey@newww.agency?subject=Kontaktanfrage">Kontakt aufnehmen</a>
        </Container>
      </div>
      <div className="section section-dark section-slider" id="slider">
        <Container>
          <h2 className="headline">Wir lieben unsere Kunden und arbeiten eng mit diesen zusammen.</h2>
          <p className="description">Bei jedem unserer Projekte setzen wir auf eine enge Zusammenarbeit mit unseren Kunden. Im Mittelpunkt steht dabei immer der spätere Nutzer, den wir ein einmaliges Erlebnis bieten wollen.</p>
          <div className="slider" id="page-slider">
            <div className="slider-img" style={{backgroundImage: `url(${slider1})`}}/>
            <div className="slider-img" style={{backgroundImage: `url(${slider2})`}}/>
            <div className="slider-img" style={{backgroundImage: `url(${slider3})`}}/>
          </div>
          <a className="create-contact" href="mailto:hey@newww.agency?subject=Kontaktanfrage">Kontakt aufnehmen</a>
        </Container>
      </div>
      <div className="section section-knowledge" id="knowledge">
        <Container>
          <Row>
            <Col lg="6" md="12" sm="12" xs="12">
              <div className="text-wrapper">
                <h3 className="knowledge-subheadline">Wusstest du, dass …</h3>
                <h2 className="knowledge-headline">50% der Entwickler Probleme lösen, die hätten vermieden werden können.</h2>
                <p className="knowledge-paragraph">
                  Eine gute Vorbereitung zählt zu den wesentlichen Faktoren einer erfolgreichen Produktentwicklung. So beschäftigt sich laut Studien fast jeder zweite Entwickler mit Problemen, die durch zeitnahe UX-Tests hätten vermieden werden können. Mit der richtiges Vorausplanung lässt sich somit Zeit für alle einsparen.
                </p>
                <a className="create-contact" href="mailto:hey@newww.agency?subject=Kontaktanfrage">Kontakt aufnehmen</a>
              </div>
            </Col>
            <Col lg="6" md="12" sm="12" xs="12">
              <div className="img-wrapper" />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="section section-dark section-line" id="line">
        <Container>
          <h2 className="headline">Wir setzen auf flexible Prozesse und arbeiten Hand in Hand.</h2>
          <p className="description">Bei all unseren Projekten setzen wir auf agile und effiziente Prozesse. Dabei decken wir von Branding über UX/UI Design bis hin zum Grafikdesign alle Bereiche ab, die für einen erfolgreichen Online-Auftritt gebraucht werden.</p>
          <Row>
            <Col lg="3" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${step1})`}}/>
                <div className="box-line">
                  <div className="line" />
                  <div className="circle" />
                </div>
                <h4 className="box-title">Recherche & Analyse</h4>
                <p className="box-description">Bevor wir mit dem eigentlichen Design anfangen, setzen wir uns intensiv mit dem Vorhaben auseinander. Im Mittelpunkt steht dabei immer der spätere Nutzer.</p>
              </div>
            </Col>
            <Col lg="3" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${step2})`}}/>
                <div className="box-line">
                  <div className="line" />
                  <div className="circle" />
                </div>
                <h4 className="box-title">Konzeptfindung</h4>
                <p className="box-description">Nach Recherche & Analyse geht es in die Konzeptphase. Hier wird die grobe richtig festgelegt und definiert, die dann an Anhaltspunkt für die weiteren Schritte dient.</p>
              </div>
            </Col>
            <Col lg="3" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${step3})`}}/>
                <div className="box-line">
                  <div className="line" />
                  <div className="circle" />
                </div>
                <h4 className="box-title">Design & Testing</h4>
                <p className="box-description">Wir erstellen detaillierte Prototypen und testen diese intensiv. Das Ergebnis ist ein ausgereiftes Screendesign, das keine Fragen offen lässt und genaue Vorgaben macht.</p>
              </div>
            </Col>
            <Col lg="3" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${step4})`}}/>
                <div className="box-line">
                  <div className="line" />
                  <div className="circle" />
                </div>
                <h4 className="box-title">Handoff & QA</h4>
                <p className="box-description">Als Full-Service Agentur wissen wir, dass die Entwicklung digitaler Produkte immer ein Zusammenspiel alle Akteure ist. Deshalb arbeiten wir eng mit den Entwicklern zusammen.</p>
              </div>
            </Col>
          </Row>
          <a className="create-contact" href="mailto:hey@newww.agency?subject=Kontaktanfrage">Kontakt aufnehmen</a>
        </Container>
      </div>
      <div className="section section-tripple" id="technologies">
        <Container>
          <h2 className="headline">Wir arbeiten mit den besten Technologien, die es gibt.</h2>
          <p className="description">Ob Adobe XD, InVision oder Zeplin, wir arbeiten mit den besten Technologien, die es gibt und richten uns nach unseren Kunden. Unser Ziel ist dabei immer die Entwicklung digitalen Produkten, die ein Wow auslösen.</p>
          <Row>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${adobe})`}}/>
                <h3 className="box-title">Adobe XD</h3>
                <p className="box-description">Neben Sketch und Figma setzen wir beim Screendesign vor allem auf Adobe XD, mit dem sich auch schnell detaillierte Prototypen bauen lassen.</p>
              </div>
            </Col>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${invision})`}}/>
                <h3 className="box-title">InVision</h3>
                <p className="box-description">InVision kommt bei uns hauptsächlich zur Kollaboration und Präsentation zum Einsatz. In den Prototypen lässt sich so detailliertes Feedback geben.</p>
              </div>
            </Col>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${zeplin})`}}/>
                <h3 className="box-title">Zeplin</h3>
                <p className="box-description">Zeplin hat sich auf die Zusammenarbeit von UX Designer und Entwicklern spezialisiert und übergibt alle wesentlichen Anforderungen und Spezifikationen.</p>
              </div>
            </Col>
          </Row>
          <a className="create-contact" href="mailto:hey@newww.agency?subject=Kontaktanfrage">Kontakt aufnehmen</a>
        </Container>
      </div>
      <div className="section section-dark section-faq" id="faq">
        <Container>
          <h2 className="headline">Häufige Fragen (FAQ)</h2>
          <Row>
            <Col lg="12" className="faq-wrapper">
              <ul>
                <li>
                  <Collapsible trigger="Wie lange dauert ein UX Design Projekt?">
                    <p>
                      Die Dauer eines UX Design Projekts hängt von einer Vielzahl unterschiedlicher Faktoren ab. In der Regel arbeiten wir mit unseren Kunden in sogenannten Design-Sprints, die einen Rahmen bieten und flexible und agile Zusammenarbeit ermöglichen.
                    </p>
                  </Collapsible>
                </li>
                <li>
                  <Collapsible trigger="Wie viel kostet ein UX Design Projekt?">
                    <p>
                      Wir richten uns bei all unseren Projekten nach dem Budget unserer Kunden und machen uns es zur Aufgabe mit den zur Verfügung stehenden Mittel das Maximum rauszuholen. Unser üblicher Stundensatz liegt im Durschnitt bei 80,00 € zzgl. MwSt. Die Kosten hängen somit immer von der Komplexität und dem Projektumfang ab.
                    </p>
                  </Collapsible>
                </li>
                <li>
                  <Collapsible trigger="Wie läuft ein UX Design Projekt ab?">
                    <p>
                      Wir starten jedes unserer Projekte mit einer intensiven Recherche, in der wir dein Vorhaben genau unter die Lupe nehmen. Weiter geht es mit der Konzeptfindung, die als Grundlage für das anschließende Screendesign dienen. Sobald dieses steht, beschäftigen wir uns auf das Handoff und die Qualitätskontrolle.
                    </p>
                  </Collapsible>
                </li>
                <li>
                  <Collapsible trigger="Welche Technologien kommen zum Einsatz?">
                    <p>
                      Wir sind flexibel was unsere eingesetzten Technologien angeht und richten uns hier nach unseren Kunden. Für das Screendesign setzen wir auf Adobe XD, Figma und Sketch. Beim Prototyping und Feedback kommt hauptsächlich InVision zum Einsatz. Für die Zusammenarbeit mit den Entwicklern greifen wir auf Zeplin zurück.
                    </p>
                  </Collapsible>
                </li>
                <li>
                  <Collapsible trigger="Wo bietet newww.agency seine Leistungen an?">
                    <p>
                      Unser Hauptsitz ist in Karlsruhe. Unsere Kunden sind hingegen weltweit zuhause. Wir betreuen unsere Kunden standortübergreifend und haben Prozesse etabliert, die eine flexible und reibungslose Zusammenarbeit ermöglichen.
                    </p>
                  </Collapsible>
                </li>
              </ul>
            </Col>
          </Row>
          <a className="create-contact" href="mailto:hey@newww.agency?subject=Kontaktanfrage">Kontakt aufnehmen</a>
        </Container>
      </div>
      <SectionContact lang='de' />
    </Layout>
  );
}

export default Uxuidesign;
